<template>
  <div class="about pa-2">
    <v-card
        class="mx-auto my-12"
        max-width="1000"
    >

      <v-img
          height="250"
          src="@/assets/img/DSC_1772-1.jpg"
      ></v-img>

      <v-card-title>Submit Your Order</v-card-title>

      <v-card-text>
        <div class="text-h6">One Final Check</div>

        <p>Before you submit your order please give your booklets a final check, this is your last chance to make any changes.</p>

      </v-card-text>

        <div class="pa-4">

          <div class="pa-2">
            <v-btn :disabled="!valid" @click="preview" class="mr-2 px-2">Preview</v-btn>
          </div>

          <div class="pa-2" v-if="absentFriendsEnabled">
              <v-btn class="mr-2 px-5" :disabled="!valid" @click="preview( {absentFriends: true} )">Absent Friends Preview</v-btn>
          </div>

        </div>



      <v-card-text>
        <v-alert
            v-model="orderSubmitted"
            text
            type="success"
        >
          Order submitted.
        </v-alert>
        <v-alert
            v-model="orderSubmitted"
            text
            type="info"
        >
            Booklet details can no longer be edited.
        </v-alert>


        <div class="mb-4 subtitle-3">
            <div class="text-h6">Ready To Submit</div>

            <p>
              When you are ready and happy with your booklets, please press submit.
            </p>
            <p>
              If the submit button is not enabled, one or more activities may not have been completed.
              Please use the left-hand menu to confirm all activities have a tick next to them, marking them as complete.
            </p>
            <p>
              Please be aware, once you have submitted your booklet, no more changes can be made.
              The booklets will then be processed and prepared for printing.
            </p>
        </div>

    <!--
        <div class="mb-4 subtitle-3">
          Date of Wedding: {{ weddingDateFormatted }}
        </div>
        <div
            v-if="absentFriendsEnabled"
            class="mb-4 subtitle-3"
        >
          "Absent Friends" option selected
        </div>
        <div
            v-if="customCoversEnabled"
            class="mb-4 subtitle-3"
        >
          "Custom Covers" option selected
        </div>

    -->

      <v-card-actions class="pt-0 px-0 pb-4">
        <v-btn
            :disabled="!allPagesComplete || readOnlyMode"
            @click="attemptSubmitOrder"
            color="green"
            class="px-15"
        >
          Submit Order
        </v-btn>
      </v-card-actions>


      


    </v-card-text>


    </v-card>
    <preview-dialog/>
  </div>

</template>

<script>
import {form} from "@/mixins/form";
import PreviewDialog from "@/components/Dialogs/DialogPreview";

export default {
  name: 'Submit',
  mixins: [form],
  components: {
    PreviewDialog
  },
  computed: {
    absentFriendsEnabled() {
      return this.$store.state.absentFriends
    },
    customCoversEnabled() {
      return this.$store.state.customCovers
    },
    weddingDateFormatted() {
      return this.$store.getters.getWeddingDateFormatted
    },
    allPagesComplete() {
      let allPagesComplete = true
      for (let i = 0; i < this.$store.state.pages.length; i++) {
        const page = this.$store.state.pages[i]
        if (page.complete === false) {
          // Check custom covers & absent friends modes, add exceptions accordingly.
          if (this.$store.state.absentFriends === false && page.id === "absentFriends") {
            continue
          }
          if (this.$store.state.customCovers === false) {
            if (page.id === "frontCover" || page.id === "backCover") {
              continue
            }
          } else {
            if (page.id === "theme") {
              continue
            }
          }
          allPagesComplete = false
        }
      }
      return allPagesComplete
    },
    orderSubmitted() {
      return !!this.$store.state.submittedOrder.regular.sourceOrderId
    }
  },
  methods: {
    attemptSubmitOrder() {
      this.$root.$confirm(
          "Submit order",
          "Once you've submitted your order you will no longer be able to make any further changes. Are you sure?"
      )
          .then(() => {
            this.submitOrder()
          })
    },
    submitOrder() {
      // Put CMS into read-only mode.
      this.$store.commit("setReadOnlyMode", true)
      // Create order object (non-AF).
      const orderObj = this.transformOrder({absentFriends: false})
      this.$store.commit("setSubmittedOrder", orderObj)
      // Create AF order object if AF is enabled.
      if (this.$store.state.absentFriends) {
        const orderObjAf = this.transformOrder({absentFriends: true})
        this.$store.commit("setSubmittedOrderAf", orderObjAf)
      }
      this.$store.commit("setOrderDateSubmitted")
      // Write to Firebase.
      this.updateAppStateInFirestore()
    },
  },
}
</script>
