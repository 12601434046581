<template>
  <v-dialog
      v-model="dialogPreview"
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.mobile"
      @click:outside="close"
  >
    <v-card>
      <v-card-title class="grey lighten-2">
        Preview
      </v-card-title>
      <v-card-text class="pa-0">

        <div v-if="previewError" class="ma-6">
          <p>Preview API error (code: {{ previewError }})</p>
        </div>

        <div v-else-if="previewImagesReady">

          <v-carousel
              height="100%"
              hide-delimiters
              show-arrows-on-hover
          >
            <v-carousel-item
                v-for="(previewImage) in previewImages"
                :key="previewImage"
            >
              <img
                  :src="previewImage"
                  alt=""
                  style="display: block; margin: auto; width: 100%;"
              />
            </v-carousel-item>
          </v-carousel>

        </div>
        <div v-else class="ma-6">
          <p>Generating your preview. Larger image files mean a longer loading time but a better quality booklet. It’s worth the wait!</p>
          <v-progress-linear
              indeterminate
              color="purple dark"
          >
          </v-progress-linear>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            @click="close"
            text
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PreviewDialog",
  methods: {
    close() {
      this.dialogPreview = false
      this.$store.commit("setPreviewError", 0)
    },
  },
  computed: {
    dialogPreview: {
      get: function () {
        return this.$store.state.dialogPreview
      },
      set(value) {
        this.$store.commit("setDialogPreview", value)
      }
    },
    previewError() {
      return this.$store.state.previewError
    },
    previewImagesReady: {
      get: function () {
        return this.$store.state.previewImagesReady
      },
      set(value) {
        this.$store.commit("setPreviewImagesReady", value)
      }
    },
    previewImages() {
      return this.$store.state.previewImages
    }
  }
}
</script>
